import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { PolkadotService } from 'src/app/shared/services/polkadot.service';
import { ConnectWalletModel } from 'src/app/shared/model/wallet.model';
import { WalletListComponent } from '../../../wallet/wallet-list/wallet-list.component';
import { WalletAccountsModel } from 'src/app/shared/model/polkadot.model';
import { CookiesService } from 'src/app/shared/services/cookies.service';
declare var require
const Swal = require('sweetalert2')
@Component({
  selector: 'app-connect-wallet',
  templateUrl: './connect-wallet.component.html',
  styleUrls: ['./connect-wallet.component.scss']
})
export class ConnectWalletComponent implements OnInit{
  closeResult: string;
  public loginForm: FormGroup;
  public show: boolean = false
  is_connect_wallet: boolean = true;
  is_polkadot_extension: boolean = false;
  wallet_info: WalletAccountsModel = new WalletAccountsModel();
  connect_wallet_info: ConnectWalletModel = new ConnectWalletModel();
  constructor(
    private fb: FormBuilder,
    config: NgbModalConfig, 
    private modalService: NgbModal,
    private polkadotService: PolkadotService,
    private cookiesService: CookiesService,
  ) {
  	// customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.loginForm = this.fb.group({
      name: ["", [Validators.required]],
      mnenomic: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  walletButton(){
    this.is_polkadot_extension = false
    this.is_connect_wallet = !this.is_connect_wallet

    if(!this.is_connect_wallet){
      this.generateMnemonic();
    }else{
      this.connect_wallet_info.mnenomic_seeds = '';
      this.connect_wallet_info.password = '';
      this.connect_wallet_info.name = '';
    }
  }
  polkadotExtension(){
    this.is_connect_wallet = !this.is_connect_wallet
    this.is_polkadot_extension = true
  }

  copyMnenomic(){
    
  }
  
  login() {
    if (this.loginForm.value["email"] == "Test@gmail.com" && this.loginForm.value["password"] == "test123") {

    }
  }
  
  showPassword(){
    this.show = !this.show
  }

  open() {
    // this.modalService.open(content, { centered: true }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
    const modalRef = this.modalService.open(WalletListComponent,{ centered: true, backdrop: true,keyboard:true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  generateMnemonic() {
    this.connect_wallet_info.mnenomic_seeds = this.polkadotService.generateMnemonic();
  }

  async createWallet() {
    // try {
    //   const address = await this.polkadotService.createPolkadotWallet(this.connect_wallet_info.mnenomic_seeds, this.connect_wallet_info.password, this.connect_wallet_info.name);
    //   if(address!=null){
    //     this.wallet_info.address = address;
    //     this.wallet_info.address_display = address.substring(0, 5) + "..." + address.substring(address.length - 5, address.length),
    //     this.wallet_info.metaName = this.connect_wallet_info.name;
    //     this.wallet_info.metaSource = 'polkadot-js';
    //     this.cookiesService.setCookieArray('wallet-info',this.wallet_info);
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'Connected',
    //       text: "Wallet Created successfully"+'!',
    //       timer: 1500,
    //       timerProgressBar: true,
    //       willClose: () => {
    //         window.location.reload()
    //       }
    //     }).then((result) => {
    //       if (result.dismiss === Swal.DismissReason.timer) {
    //         window.location.reload()
    //       }
    //     }) 
    //   }
    // } catch (error) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Error!',
    //     text: "Mnenomic Seed is already use",
    //     timer: 1000,
    //     timerProgressBar: true,
    //     willClose: () => {
    //       this.connect_wallet_info.mnenomic_seeds = this.polkadotService.generateMnemonic();
    //     }
    //   }).then((result) => {
    //     this.connect_wallet_info.mnenomic_seeds = this.polkadotService.generateMnemonic();
    //   })
      
    // }
  }
  connectWallet(){
    // const address = this.polkadotService.connectPolkadotWallet(this.connect_wallet_info.mnenomic_seeds, this.connect_wallet_info.password);
    // console.log(address);
  }
  ngOnInit(): void {
    
  }
}
