import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NFTModel } from '../model/nft.model';
import { AppSettings } from '../../app-settings';
import { PolkadotService } from './polkadot.service';
import { CookiesService } from './cookies.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NftService {
  constructor(
    private httpClient: HttpClient,
    private polkadotService: PolkadotService,
    private cookiesService: CookiesService
  ) { }

  public defaultAPIURLHost: string = environment.APIURLHostNFT;
  private collectionId = environment.collectionId;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'websocket': this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').wsProviderEndpoint  :environment.network[0].networks[0].wsProviderEndpoint
    }),
  };

  getAllNft(): Observable<[boolean, any]> {
      return new Observable<[boolean, any]>((observer) => {
      let nft_list: NFTModel[] = [];
      let collection_id = this.collectionId;
      this.httpClient.post<any>(
          this.defaultAPIURLHost +
          '/nfts/marketplace',
          { collection_id },
          this.httpOptions
      ).subscribe({
          next: (response) => {
          let results = response;
          // let result_data = results['data'];
          if (results != null) {
              var data = results;
              if (data.length > 0) {
              for (let i = 0; i <= data.length - 1; i++) {
                  nft_list.push({
                  nftTokenId: data[i].nftTokenId,
                  imagePath: data[i].imagePath,
                  name:data[i].name,
                  description: data[i].description,
                  price: data[i].price,
                  isForSale: data[i].isForSale,
                  isEquipped: data[i].isEquipped,
                  category: data[i].category,
                  collection: data[i].collection,
                  astroType: data[i].astroType,
                  rarity: data[i].rarity,
                  network: data[i].network,
                  blockchainId: data[i].blockchainId,
                  collectionId: data[i].collectionId,
                  tokenOwner: data[i].tokenOwner,
                  });
              }
              } else {
              nft_list = [];
              }
          }
          observer.next([true, nft_list]);
          observer.complete();
          },
          error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
          }
      });
      });
  }

  getNftById(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let nft: NFTModel[] = [];
      let nftTokenId = id.toString();
      this.httpClient.get<any>(
        this.defaultAPIURLHost +
        '/nfts/id/' +
        nftTokenId,
        this.httpOptions
      ).subscribe({
        next: (response) => {
          let results = response;

          // let result_data = results['data'];
          if (results != null) {
            nft = results;
          }
          observer.next([true, nft]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
        }
      });
    });
  }

  async buyNFT(
    nft: NFTModel,
    recepient: string,
    data: any,
    price: any
  ): Promise<Observable<[boolean, any]>> {
    await this.polkadotService.transferBalanceReady(data);
    return new Observable<[boolean, any]>((observer) => {
      let id = nft.nftTokenId;
      let buyer = recepient;
      let from = nft.tokenOwner;
      this.httpClient.post<any>(
        this.defaultAPIURLHost +
        '/nfts/buynft',
        { from, buyer, id, price },
        this.httpOptions
      ).subscribe({
        next: (response) => {
          let results = response;

          if (results != null && results.status === 200) {
            // nft = results;
            // this.withdrawBalance(price);
          }
          observer.next([true, nft]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
        }
      });
    });
  }

  async withdrawBalance(
    price: any,
    from: any
  ): Promise<Observable<[boolean, any]>> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(
        this.defaultAPIURLHost +
        '/nfts/withdrawbalance',
        { price, from },
        this.httpOptions
      ).subscribe({
        next: (response) => {
          let results = response;

          // if (results != null && results.status === 200) {
          //   // nft = results;
          // }
          observer.next([true, results]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
        }
      });
    });
  }

  async getSmartContract(): Promise<Observable<[boolean, any]>> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.get<any>(
        this.defaultAPIURLHost +
        '/chain/smartcontract',
        this.httpOptions
      ).subscribe({
        next: (response) => {
          this.cookiesService.setCookie('contract_address', response.smartcontract);
          observer.next([true, response]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
        }
      });
    });
  }
}
