import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() is_loading: boolean;
  public isContentShow: boolean = true;
  public show: boolean = true;

  constructor() {
    // setTimeout(() => {
    //   this.show = false;
    // }, 3000);
    if(this.is_loading==true){
      this.show = true;
      this.isContentShow = false
    }
  }

  ngOnInit() { }

  ngOnDestroy() { }

}
