<div class="media profile-media">
  <a class="btn btn-primary btn-hover-effect media-body m-l-0" 
  href="javascript:void(0)" 
  placement="bottom" 
  ngbTooltip="Connect Wallet" 
  (click)="open()">
      <span class="ms-1"> 
          <svg class="fill-icon" style="width: 20px; height:20px;stroke:rgb(255, 255, 255)">
              <use href="assets/svg/icon-sprite.svg#stroke-wallet"></use>
          </svg>
      </span> 
      <span style="color: rgb(255, 255, 255);" class="connect-wallet">{{'Connect Wallet' | translate}}</span>
  </a>
</div>

<!-- <ng-template #content let-modal>
<div class="modal-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <app-wallet-list></app-wallet-list>
</div>
</ng-template> -->

<!-- <ng-template #content let-modal>
<div class="modal-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo" routerLink="/">
                <img class="img-fluid for-light" src="assets/images/logo/logo.webp" alt="" />
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.webp" alt="" />
              </a>
            </div>
            <div class="login-main">
              <ng-container *ngIf="is_connect_wallet">
                <app-wallet-list></app-wallet-list>
                <p class="mt-4 mb-0">
                  Don't have wallet?
                  <a href="javascript:void(0)" (click)="walletButton()">Create Wallet</a>
                </p>
              </ng-container>
                <form class="theme-form" [formGroup]="loginForm" *ngIf="is_connect_wallet && !is_polkadot_extension" (ngSubmit)="connectWallet()">
                    <h4>{{'Connect your wallet' | translate}}</h4>
                    <p>{{'Enter your mnenomic seeds and password' | translate}}</p>

                    <div class="form-group">
                      <label class="col-form-label">{{'Name' | translate}}</label>
                      <input class="form-control" formControlName="name" required="" placeholder=""  [(ngModel)]="connect_wallet_info.name"/>
                      <div *ngIf="loginForm.controls.name.touched && loginForm.controls.name.errors?.required" class="text text-danger mt-1">{{'Name is required' | translate}}</div>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Mnenomic Seed</label>
                      <textarea class="form-control" id="bm-desc" required="" autocomplete="off" formControlName="mnenomic" [(ngModel)]="connect_wallet_info.mnenomic_seeds"></textarea>
                      <div *ngIf="loginForm.controls.mnenomic.touched && loginForm.controls.mnenomic.errors?.required" class="text text-danger mt-1">{{'Mnenomic Seed is required' | translate}}</div>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">{{'Password' | translate}}</label>
                      <input class="form-control" [type]="show ? 'text' : 'password'" [(ngModel)]="connect_wallet_info.password" formControlName="password" required="" placeholder="" />
                      <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                      <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                      <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">{{'Password is required' | translate}}</div>
                    </div>
                    
                    <div class="form-group mb-0">
                        <div class="text-end mt-3">
                          <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()" type="submit"><span>Connect Wallet</span></button>
                        </div>
                      </div>
                      <p class="mt-4 mb-0">
                        Use
                        <a href="javascript:void(0)" (click)="polkadotExtension()">Polkadot Extension</a>
                      </p>
                    <p class="mt-4 mb-0">
                      Don't have wallet?
                      <a href="javascript:void(0)" (click)="walletButton()">Create Wallet</a>
                    </p>
                </form>
                <form class="theme-form" [formGroup]="loginForm" *ngIf="!is_connect_wallet && !is_polkadot_extension" (ngSubmit)="createWallet()">
                    <h4>{{'Create your Wallet' | translate}}</h4>
                    <p>{{'Enter your password to create wallet' | translate}}</p>

                    <div class="form-group">
                      <label class="col-form-label">{{'Name' | translate}}</label>
                      <input class="form-control" formControlName="name" required="" placeholder=""  [(ngModel)]="connect_wallet_info.name"/>
                      <div *ngIf="loginForm.controls.name.touched && loginForm.controls.name.errors?.required" class="text text-danger mt-1">{{'Name is required' | translate}}</div>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">{{'Mnenomic Seed' | translate}}</label>
                      <textarea class="form-control" id="bm-desc" required="" formControlName="mnenomic" autocomplete="off" [(ngModel)]="connect_wallet_info.mnenomic_seeds" style="padding-right: 50px;pointer-events: none;"></textarea>
                      <div class="show-hide" (click)="copyMnenomic()" style="top: 65px !important;"><span class="copy">copy</span></div>
                      <div class="text text-danger mt-1">{{'Losing your mnemonic seed is like losing your wallet. Keep it confidential and store it in a secure location.' | translate}}</div>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Password</label>
                      <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                      <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                      <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                      <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">{{'Password is required' | translate}}</div>
                    </div>
                    <div class="form-group mb-0">
                        <div class="text-end mt-3">
                          <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid"  type="submit"><span>{{'Create Wallet' | translate}}</span></button>
                        </div>
                      </div>
                    <p class="mt-4 mb-0">
                        {{'Already have an wallet?' | translate}}
                      <a href="javascript:void(0)" (click)="walletButton()" class="ms-2">{{'Connect Wallet' | translate}}</a>
                    </p>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-template> -->
